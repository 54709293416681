import React, { useCallback, useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../../components/breadcrumbs';
import CustomSelect from '../../../components/select';
import UserService from '../../../services/user';
import ExchangeIcon from '../../../components/icons/exchange';

import ReactSelect from 'react-select';
import FlowchartService from '../../../services/flowchart';
import DataTable from 'react-data-table-component';
import RemoveIcon from '../../../components/icons/remove';

const AllocateOperador = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataFlowcharts, setDataFlowcharts] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [dataPermissions, setDataPermissions] = useState([]);
  const [aUser, setaUser] = useState('');
  const [erros, setErros] = useState({});
  const [sucess, setSucess] = useState({});
  const [alert, setAlert] = useState(false);

  const id = useParams();

  const fetchFlowchartsPermissions = useCallback(async () => {
    try {
      const flowchartsPermissions = await FlowchartService.getFlowchartPermissions(id.id);
      if (flowchartsPermissions && flowchartsPermissions.data.data) {
        setDataPermissions(flowchartsPermissions.data.data);
        setAlert(true);
        setErros('');
      } else {
        setErros('Não foi possível obter as permissões.');
      }
    } catch (error) {
      setErros('Erro ao obter permissões.');
    }
  }, [id.id]); 

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("EDITOR")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  useEffect(() => {
    const fetchFlowcharts = async () => {
      const flowcharts = await FlowchartService.getFlowcharts();
      if (flowcharts) {
        let flowchartSelect = flowcharts.data.data;
        let transformedFlowcharts = flowchartSelect.map(flowchart => ({
          label: flowchart.name,
          value: flowchart.flowchart_id,
        }));
        setDataFlowcharts(transformedFlowcharts)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };

    const fetchUsers = async () => {
      const user = await UserService.getUsers();
      if (user) {
        let userSelect = user.data.data
        let transformedUsers = userSelect.filter(user => user.roles.includes("OPERATOR"))
        .map(user => ({
          label: user.name,
          value: user.user_id,
        }));;
        setDataUsers(transformedUsers)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };


    fetchUsers();
    fetchFlowcharts();
  }, [navigate, id]);

  useEffect(() => {
    fetchFlowchartsPermissions();
  }, [fetchFlowchartsPermissions]);

  const handleAllocate = async () => {
    let valid = true;
    let erros = {};
    let user = aUser;

    if (!user) {
        erros.user = 'O operador é obrigatório';
        valid = false;
    }

    if (valid) {
        try {
            const response = await FlowchartService.createPermissions(
                id.id,
                user
            );
            if (response.status === 200) {
                setErros({});
                const newSucess = { newPermission: "Permissão liberada com sucesso!" };
                setAlert(true);
                setSucess(newSucess)
                setaUser([]);
                fetchFlowchartsPermissions();

                setTimeout(() => {
                  setAlert(false);
                }, 3000);
            } else {
                erros.submit = response.message;
            }
        } catch (error) {
            erros.submit = error.message.data;
        }
        
        if(erros.submit) {
            setErros(erros);
            setAlert(true)
        }
    } else {
        setErros(erros);
    }
  };

  const handleDeletePermissions = async (user_id) => {
    let erros = {};

    try {
      const response = await FlowchartService.deleteFlowchartPermissions(
          id.id,
          user_id
      );
      if (response.status === 200) {
          setErros({});
          const deleteSucess = { deletePermission: "Permissão excluída com sucesso!" };
          setSucess(deleteSucess)
          setAlert(true);
          fetchFlowchartsPermissions();

          setTimeout(() => {
            setAlert(false);
          }, 3000);
      } else {
          erros.submitDelete = response.message;
      }
    } catch (error) {
        erros.submitDelete = error.message.data;
    }

    if(erros.submitDelete) {
      setErros(erros);
      setAlert(true)
    }
  }

  const isMultic = false;

  const handleChange = (selectedOption) => {
    // Adiciona o name ao chamar o onChange, se existir
    const value = isMultic
    ? selectedOption?.map(option => option.value)
    : selectedOption ? selectedOption.value : "";

    // // Atualiza o estado
    setaUser(value);
  };

  const selectedFlowchart = dataFlowcharts.find(item => {
    const isMatch = parseInt(item.value) === parseInt(id.id);
    return isMatch;
  });

  function getUserByName(value) {
    const user = dataUsers.find(item => item.value === value);
    return user ? user.label : null;
  }

  const RemoveOperador = ({ user_id }) => (
    <span onClick={() => handleDeletePermissions(user_id)}>
      <RemoveIcon />
    </span>
  );

  const columns = [
    {
      name: 'ID',
      selector: row => row.user_id,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Nome',
      selector: row => getUserByName(row.user_id),
      sortable: true
    },
    {
      name: 'E-mail',
      selector: row => row.user_email,
      sortable: true,
    },
    {
      selector: row => row.user_id,
      cell: row => <RemoveOperador user_id={row.user_id} />,
      width: "50px",
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Operadores por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Gerenciar Permissões dos Operadores</h1>
          </div>
        </header>
        <section>
        <div className='container-panel'>
            <div className='container-panel-select'>
                <CustomSelect 
                    label="Processo" 
                    options={dataFlowcharts} 
                    value={selectedFlowchart ? selectedFlowchart.value : ''} 
                    readonly={true}
                />

                <div className="input_p container_exchange_icon"> <ExchangeIcon /> </div>

                <div className='select'>
                    <label>Operador</label>
                    <ReactSelect 
                        isMulti={isMultic} // Suporte a múltiplos selecionados
                        options={dataUsers} 
                        value={dataUsers.find(option => option.value === aUser)} // Mapeia os valores selecionados
                        onChange={handleChange} 
                        placeholder="Digite para buscar..."
                    /> 
                     {erros.user && <span className='texterror'>{erros.user}</span>}
                </div>

            </div>

            <div className='container-alerts'>
              {alert && !erros.submit && !erros.submitDelete && sucess.newPermission && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>{sucess.newPermission}</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
            </div>

            <div className='container-panel-button'>
              <Button variant="primary" onClick={handleAllocate}>
                Liberar permissões dos operadores
              </Button>
            </div>
          </div>
          <div>
            <DataTable
              columns={columns}
              data={dataPermissions}
              pagination
              responsive
              striped
              paginationComponentOptions={paginationComponentOptions}
              noDataComponent={<div>Processo não possui operadores com permissão de uso.</div>}
            />

            <div className='container-alerts'>
              {alert && !erros.submitDelete && !erros.submit && sucess.deletePermission && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>{sucess.deletePermission}</Alert>}
              {alert && erros.submitDelete && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submitDelete}</Alert>}
            </div>
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default AllocateOperador;