import "./index.scss";

const DownloadIcon = () => {
    return (
        <span className="icon">
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={24} 
                height={24} 
                viewBox="0 0 24 24" 
                fill="currentColor" 
                className="icon icon-tabler icon-tabler-download">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path 
                    d="M12 3v10m-4-4l4 4 4-4" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    fill="none" 
                />
                <path 
                    d="M8 17h8" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                />
                <path 
                    d="M5 20h14a2 2 0 0 0 2 -2v-4a1 1 0 0 0 -1 -1h-16a1 1 0 0 0 -1 1v4a2 2 0 0 0 2 2z" 
                    fill="currentColor" 
                />
            </svg>
        </span>
    );
};

export default DownloadIcon;