import React, { useEffect, useState } from "react";
import AuthService from "../../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Form, Button, Alert } from "react-bootstrap";
import FlowchartService from "../../../services/flowchart";
import InputsService from "../../../services/inputs";
import Convert from "../../../utils/convert";
import Loading from "../../../components/loading";
import BackIcon from "../../../components/icons/back";
import "../index.scss";

const FormPage = () => {
  const navigate = useNavigate();
  const [roleUser, setRoleUser] = useState("");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [flowchart_id, setFlowchartID] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          setRoleUser(role.data.data.roles);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate("/login");
      }
    };

    const fetchPropertiesElement = async () => {
      const element_data = JSON.parse(localStorage.getItem("elementData"));
      if (element_data) {
        setFlowchartID(element_data.flowchart_id);
        try {
          const Element = await FlowchartService.getElementID(
            element_data.flowchart_id,
            element_data.element_id
          );
          if (Element.status === 200) {
            const steps = Element.data.data.properties;
            const initialData = steps.reduce((acc, step) => {
              acc[step.name] = { ...step, value: step.value || "" };
              return acc;
            }, {});
            setFormData(initialData);
          }
        } catch (error) {
          console.error("Erro ao carregar propriedades:", error);
        }
      }
    };

    fetchRole();
    fetchPropertiesElement();
  }, [navigate, id]);

  const handleBackPage = () => {
    navigate(-1);
  };

  const Back = ({ onClick }) => (
    <button onClick={onClick} style={{ background: "none", border: "none" }}>
      <BackIcon />
    </button>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value,
      },
    }));
  };

  //valida campos e verifica se todos os campos estão vazios
  const validateFields = () => {
    const filledFields = Object.values(formData).filter((field) => field.value);
    if (filledFields.length === 0) {
      setAlert({
        type: "danger",
        message:
          "Atenção! Preencha pelo menos um campo antes de enviar o formulário.",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ type: "", message: "" });
    try {
      if (!validateFields()) {
        setLoading(false);
        return;
      }

      const element_data = JSON.parse(localStorage.getItem("elementData"));
      
      const dataToSend = Object.values(formData)
        .filter((field) => field.value)
        .map((field) => ({
          property_id: field.property_id,
          value: field.value,
          data_id: field.data_id,
        }));

      const responses = await Promise.all(
        dataToSend.map(async (data) => {
          if (!data.data_id) {
            return InputsService.includeInputData(
              flowchart_id,
              element_data.element_id,
              element_data.event_id,
              data.property_id,
              data.value
            );
          } else {
            return InputsService.editInputData(
              flowchart_id,
              element_data.element_id,
              element_data.event_id,
              data.data_id,
              data.property_id,
              data.value
            );
          }
        })
      );

      const success = responses.every((response) => response.status === 200);
    if (success) {
      setAlert({
        type: "success",
        message: "Formulário enviado com sucesso!",
      });
      setTimeout(() => navigate(-1), 3000);
    } else {
      setAlert({
        type: "danger",
        message: "Erro ao enviar formulário. Tente novamente.",
      });
    }
  } catch (error) {
    console.error("Erro ao enviar dados:", error);
    setAlert({
      type: "danger",
      message:
        "Erro ao enviar formulário. Verifique sua conexão e tente novamente.",
    });
  } finally {
    setLoading(false);
  }
  };

  return (
    <Container className="container-custom-fluid form-page">
      <Row className="form_container">
        <header className="header header_operator">
          <div>
            <Back onClick={handleBackPage} />
            <div className="header_operador_form">
              <h1>Formulário</h1>
            </div>
          </div>
        </header>
        <section className="container_card_element form_page_container">
          {loading && <Loading />}
          <form onSubmit={handleSubmit} className="form_page">
            {Object.keys(formData).map((key) => {
              const field = formData[key];
              return (
                <div key={field.property_id} className="form-group">
                  <label className="label_field">{field.name}</label>
                  <p>{field.description}</p>
                  {field.data_type === "textarea" ? (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                    />
                  ) : (
                    <Form.Control
                      type={Convert.convertOptionsDataTypeForm(field.data_type)}
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      className="form_input"
                    />
                  )}
                </div>
              );
            })}
            <div className="form_actions">
              <Button type="submit" variant="primary">
                Enviar
              </Button>
            </div>
          </form>
          {alert.message && (
            <Alert
              variant={alert.type}
              onClose={() => setAlert({ type: "", message: "" })}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
        </section>
      </Row>
      <div className="container_triangle">
        <svg
          width="375"
          height="279"
          viewBox="0 0 375 279"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M376.745 278.207C376.752 278.767 376.298 279.223 375.739 279.219L308.9 278.782C308.014 278.776 307.573 277.705 308.198 277.077L374.225 210.679C374.851 210.051 375.924 210.486 375.934 211.372L376.745 278.207Z"
            fill="#F48120"
          />
          <path
            d="M308.415 278.432C308.419 278.991 307.963 279.445 307.403 279.439L240.568 278.681C239.681 278.671 239.245 277.598 239.873 276.973L306.219 210.892C306.847 210.267 307.918 210.707 307.924 211.593L308.415 278.432Z"
            fill="#FCB813"
          />
          <path
            d="M164.539 212.997C164.544 213.557 164.088 214.011 163.528 214.005L96.6924 213.247C95.806 213.237 95.37 212.164 95.9981 211.538L162.343 145.458C162.971 144.832 164.043 145.273 164.049 146.159L164.539 212.997Z"
            fill="#FCB813"
          />
          <path
            d="M193.809 280.561C193.804 281.106 193.361 281.547 192.815 281.55L97.0204 282.138C96.1256 282.143 95.6746 281.061 96.3086 280.429L193.074 184.05C193.708 183.419 194.789 183.874 194.78 184.769L193.809 280.561Z"
            fill="#F48120"
          />
          <path
            d="M307.77 209.934C307.774 210.494 307.319 210.948 306.759 210.942L169.686 209.387C168.8 209.377 168.364 208.304 168.992 207.679L305.059 72.1551C305.687 71.5296 306.758 71.9699 306.764 72.8563L307.77 209.934Z"
            fill="#FCB813"
          />
          <path
            d="M191.509 208.592C191.514 209.152 191.057 209.607 190.497 209.6L169.718 209.343C168.832 209.332 168.397 208.26 169.025 207.635L189.63 187.112C190.258 186.486 191.328 186.926 191.336 187.812L191.509 208.592Z"
            fill="#EC1C24"
          />
          <path
            d="M390.803 84.4823C390.904 85.1499 390.328 85.7235 389.661 85.6199L306.612 72.7278C305.802 72.6021 305.479 71.6094 306.06 71.0311L376.549 0.823393C377.129 0.245153 378.121 0.572274 378.243 1.38247L390.803 84.4823Z"
            fill="#F48120"
          />
        </svg>
      </div>
    </Container>
  );
};

export default FormPage;
