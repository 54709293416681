import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import axios from "axios";
import { saveAs } from "file-saver";
import "./extraction.scss";
import { extractData } from "../../../../services/extractapi";
import Breadcrumbs from "../../../../components/breadcrumbs";
import Loading from "../../../../components/loading";
import DataTable from "react-data-table-component";
import FlowchartService from "../../../../services/flowchart";

const ExtractionPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(24);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [flowcharts, setFlowcharts] = useState([]);
  const [fetchingFlowcharts, setFetchingFlowcharts] = useState(false);

  const columns = [
    {
      name: "ID do Elemento",
      selector: (row) => row.element_id ?? "-",
      sortable: true,
    },
    {
      name: "Nome do Elemento",
      selector: (row) => row.element_name ?? "-",
      sortable: true,
    },
    {
      name: "Elemento Ativo",
      selector: (row) => (row.element_active ? "Sim" : "Não"),
      sortable: true,
    },
    {
      name: "ID do Processo",
      selector: (row) => row.flowchart_id ?? "-",
      sortable: true,
    },
    {
      name: "Tipo de Elemento",
      selector: (row) => row.element_type ?? "-",
      sortable: true,
    },
    {
      name: "ID do Evento",
      selector: (row) => row.event_id ?? "-",
      sortable: true,
    },
    {
      name: "Data de Criação",
      selector: (row) =>
        row.event_create_at
          ? new Date(row.event_create_at).toLocaleString()
          : "-",
      sortable: true,
    },
    {
      name: "Evento Aberto",
      selector: (row) => (row.event_open ? "Sim" : "Não"),
      sortable: true,
    },
    {
      name: "ID do Dado",
      selector: (row) => row.input_id ?? "-",
      sortable: true,
    },
    {
      name: "Valor do Dado",
      selector: (row) => row.input_value ?? "-",
      sortable: true,
    },
    {
      name: "Tipo do Dado",
      selector: (row) => row.input_type ?? "-",
      sortable: true,
    },
    {
      name: "Valor Mínimo",
      selector: (row) => row.input_min ?? "-",
      sortable: true,
    },
    {
      name: "Valor Máximo",
      selector: (row) => row.input_max ?? "-",
      sortable: true,
    },
    {
      name: "Especificação Superior",
      selector: (row) => row.upper_spec ?? "-",
      sortable: true,
    },
    {
      name: "Especificação Inferior",
      selector: (row) => row.lower_spec ?? "-",
      sortable: true,
    },
    {
      name: "ID da Unidade de Medida",
      selector: (row) => row.measurement_id ?? "-",
      sortable: true,
    },
    {
      name: "Nome da Unidade de Medida",
      selector: (row) => row.measurement_name ?? "-",
      sortable: true,
    },
    {
      name: "ID da Frequência",
      selector: (row) => row.frequency_id ?? "-",
      sortable: true,
    },
    {
      name: "Nome da Frequência",
      selector: (row) => row.frequency_name ?? "-",
      sortable: true,
    },
    {
      name: "Nome da Propriedade",
      selector: (row) => row.property_name ?? "-",
      sortable: true,
    },
    {
      name: "Propriedade Ativa",
      selector: (row) => (row.property_active ? "Sim" : "Não"),
      sortable: true,
    },
  ];

  useEffect(() => {
    //carrega os processos/fluxogramas ativos
    const loadFlowcharts = async () => {
      setFetchingFlowcharts(true);
      try {
        const response = await FlowchartService.getFlowcharts();
        if (response?.data?.data) {
          const activeFlowcharts = response.data.data.filter(
            (item) => item.active
          );
          setFlowcharts(activeFlowcharts);
          if (activeFlowcharts.length > 0) {
            setSelectedProcess(activeFlowcharts[0].flowchart_id);
          }
        } else {
          setErrorMessage("Erro ao carregar fluxogramas ativos.");
        }
      } catch (error) {
        console.error("Erro ao buscar fluxogramas:", error);
        setErrorMessage("Erro ao buscar fluxogramas ativos. Tente novamente.");
      } finally {
        setFetchingFlowcharts(false);
      }
    };

    loadFlowcharts();
  }, []);

  const fetchData = async () => {
    if (!startDate || !endDate || !selectedProcess) {
      setErrorMessage(
        "Por favor, preencha as datas de início e fim e selecione um processo."
      );
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffDays = Math.ceil(Math.abs(end - start) / (1000 * 60 * 60 * 24));

    if (diffDays > 7) {
      setTableData([]);
      setErrorMessage("O intervalo de datas não pode ser maior que 7 dias.");
      return;
    }

    setLoading(true);
    setIsLoading(true);
    setErrorMessage("");
    setTableData([]);

    try {
      // console.log(
      //   `Iniciando extração: start_date=${startDate}, end_date=${endDate}`
      // );

      const flowchartIds = [selectedProcess];
      let allData = [];

      for (const flowchartId of flowchartIds) {
        try {
          const response = await extractData(flowchartId, startDate, endDate);
          // console.log("Dados extraídos: ", response);

          if (response && typeof response.data === "object") {
            // console.log(
            //   `Dados retornados para flowchart_id=${flowchartId}:`,
            //   response.data
            // );
            allData = [...allData, ...transformToTableFato(response.data)];
          } else {
            console.warn(
              `Resposta inesperada para flowchart_id=${flowchartId}:`,
              response
            );
            setErrorMessage(
              `Erro ao processar o fluxograma ${flowchartId}. Resposta inválida do servidor.`
            );
          }
        } catch (error) {
          console.error(`Erro ao processar fluxograma ${flowchartId}:`, error);
          setErrorMessage(
            `Erro ao extrair dados do fluxograma ${flowchartId}. Verifique os logs.`
          );
        }
      }

      if (allData.length > 0) {
        setTableData(allData);
      } else {
        setTableData([]);
        setErrorMessage(
          "Nenhum dado foi retornado para esse processo. Verifique os parâmetros e tente novamente."
        );
      }
    } catch (error) {
      console.error("Erro geral ao chamar a API:", error);
      setErrorMessage("Erro ao extrair os dados. Verifique os logs.");
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const transformToTableFato = (jsonData) => {
    if (!jsonData || typeof jsonData !== "object") {
      console.error("Dados inválidos recebidos para transformação:", jsonData);
      return [];
    }

    const result = [];

    Object.keys(jsonData).forEach((elementId) => {
      const element = jsonData[elementId]?.element_data;
      const events = jsonData[elementId]?.events || [];
      const dataItems = jsonData[elementId]?.data || [];

      if (!element) {
        console.warn(`Elemento com ID ${elementId} está faltando no JSON.`);
        return;
      }

      events.forEach((event) => {
        dataItems.forEach((data) => {
          const property = data.element_property || {};

          result.push({
            element_id: element.element_id,
            element_name: element.name,
            element_active: element.active,
            flowchart_id: element.flowchart_id,
            element_type: element.element_type,
            event_id: event.event_id,
            event_create_at: event.create_at,
            event_open: event.is_open,
            input_id: data.data_id,
            input_value: data.value,
            input_type: property.data_type,
            input_min: property.min_value,
            input_max: property.max_value,
            upper_spec: property.upper_specification,
            lower_spec: property.lower_specification,
            measurement_id: property.measurement_id,
            measurement_name: property.measurement_name || "-",
            frequency_id: property.frequency_id,
            frequency_name: property.frequency_name || "-",
            property_name: property.name,
            property_active: property.active,
          });
        });
      });
    });

    return result;
  };

  const exportCSV = () => {
    const header = [
      "ID do Elemento",
      "Nome do Elemento",
      "Elemento Ativo",
      "ID do Processo",
      "Tipo de Elemento",
      "ID do Evento",
      "Data de Criação do Evento",
      "Evento Aberto",
      "ID do Dado",
      "Valor do Dado",
      "Tipo do Dado",
      "Valor Mínimo",
      "Valor Máximo",
      "Especificação Superior",
      "Especificação Inferior",
      "ID da Unidade de Medida",
      "Nome da Unidade de Medida",
      "ID da Frequência de Coleta",
      "Nome da Frequência de Coleta",
      "Nome da Propriedade",
      "Propriedade Ativa",
    ].join(",");

    const csvRows = tableData.map((row) =>
      [
        row.element_id,
        row.element_name,
        row.element_active,
        row.flowchart_id,
        row.element_type,
        row.event_id,
        row.event_create_at,
        row.event_open,
        row.input_id,
        row.input_value,
        row.input_type,
        row.input_min,
        row.input_max,
        row.upper_spec,
        row.lower_spec,
        row.measurement_id,
        row.measurement_name,
        row.frequency_id,
        row.frequency_name,
        row.property_name,
        row.property_active,
      ].join(",")
    );

    const csvContent = [header, ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "extracao_tabela_fato.csv");
  };

  return (
    <Container className="container-custom-fluid">
      {isLoading && <Loading />}
      <Row className="my-4">
        <header className="header" style={{ marginTop: "4rem" }}>
          <div>
            <Breadcrumbs />
            <h1>Extração de Dados</h1>
          </div>
        </header>

        <section
          style={{
            width: "100%",
          }}
        >
          <div className="btn-group">
            <Form.Group>
              <Form.Label>Data de Início</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data de Fim</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Processo</Form.Label>
              <Form.Select
                value={selectedProcess}
                onChange={(e) => setSelectedProcess(Number(e.target.value))}
                disabled={fetchingFlowcharts}
                style={{ minWidth: "5rem" }}
              >
                {flowcharts.map((flowchart) => (
                  <option
                    key={flowchart.flowchart_id}
                    value={flowchart.flowchart_id}
                  >
                    {`${flowchart.flowchart_id} - ${flowchart.name}`}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button
              variant="primary"
              onClick={fetchData}
              disabled={loading}
              className="btn-extract"
            >
              {loading ? "Extraindo..." : "Extrair"}
            </Button>
          </div>
        </section>
      </Row>
      {errorMessage && (
        <Row
          className="mt-3"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col>
            <div style={{ color: "red", fontWeight: "bold" }}>
              {errorMessage}
            </div>
          </Col>
        </Row>
      )}
      {tableData.length > 0 && (
        <>
          <Row className="mt-3" style={{ width: "100%" }}>
            <Col className="btn-csv-container">
              <Button variant="success" onClick={exportCSV}>
                Exportar para CSV
              </Button>
            </Col>
          </Row>
          <section style={{ maxWidth: "100%", margin: "0 auto" }}>
            <DataTable
              columns={columns}
              data={tableData}
              pagination
              striped
              responsive
              noDataComponent={<div>Nenhum dado disponível.</div>}
            />
          </section>
        </>
      )}
    </Container>
  );
};

export default ExtractionPage;
