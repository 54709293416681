import axios from "axios";

const ExtractAPI = axios.create({
  baseURL: "https://mesbackend.intelligentsystems.com.br",
  headers: {
    "Content-Type": "application/json",
  },
});

ExtractAPI.interceptors.request.use((config) => {
  // pega o token do usuario no localstorage
  const user = localStorage.getItem("user");
  const userData = localStorage.getItem("user_data");
  let token = null;

  try {
    if (userData) {
      token = JSON.parse(userData).token; 
    } else if (user) {
      token = JSON.parse(user).token; //se não tiver o token em userData pega o de user no ls
    }
  } catch (error) {
    console.error("Erro ao analisar os dados do token:", error);
  }

  if (token) {
    config.headers.Authorization = `Token ${token}`;
  } else {
    console.error("Token de autenticação não encontrado.");
  }

  return config;
});

export const extractData = async (flowchartId, startDate, endDate) => {
  try {
    const response = await ExtractAPI.get(`/inputs/extract/${flowchartId}/all/`, {
      params: { start_date: startDate, end_date: endDate },
    });
    return response.data; 
  } catch (error) {
    console.error("Erro na API de extração:", error);
    throw error; 
  }
};

export default ExtractAPI;
