import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import MeasurementService from '../../../../services/measurement';
import CustomSelect from '../../../../components/select';
import InputsService from '../../../../services/inputs';
import { useParams } from 'react-router-dom';
import MovementIcon from '../../../../components/icons/movement';

const OpenCurrent = ({ element, event, handleElementCreated }) => {
  const [show, setShow] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [quality, setQuality] =  useState('');
  const [name, setNome] =  useState('');
  const [measurement_id, setMeasurementId] = useState('');
  const [measures, setDataMeasures] = useState([]);
  const id = useParams();

  useEffect(() => {
    const fetchMeasurement = async () => {
      const measure = await MeasurementService.getMeasurements();
      if (measure) {
        let measureSelect = measure.data.data;
        let transformedMeasure = measureSelect
          .filter(measure => measure.active  && measure.use_in_moviment)
          .map(measure => ({
            label: measure.name,
            value: measure.measurement_id,
          }));
        setDataMeasures(transformedMeasure);
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };
  
    fetchMeasurement();
  }, [element]);  

  const handleNewFrequency = async () => {
    let valid = true;
    let erros = {};

    if (!quality.trim()) {
        erros.quality = 'A quantidade é obrigatório';
        valid = false;
    }

    if (!name.trim()) {
        erros.name = 'O nome é obrigatório';
        valid = false;
    }

    if (!measurement_id.target.value) {
        erros.measures = 'A unidade de medida é obrigatório';
        valid = false;
    }

    if (valid) {
      try {
        const response = await InputsService.openCurrent (
            id.id,
            element.element_id,
            event.event_id,
            quality,
            name,
            measurement_id.target.value
        );
        if (response.status === 200) {
            setErros({});
            setAlert(true);
            
            setQuality();
            setNome();
            setMeasurementId();

            setTimeout(() => {
              setShow(false);
              handleClose();
            }, 3000);
        } else {
            erros.submit = response.message;
            setErros(erros);
            setAlert(true);
        }
      } catch (error) {
          return error;
      }
    } else {
      setErros(erros);
    }

    handleElementCreated();
  };

  const handleClose = () => {
    setErros({});
    setShow(false);
    setAlert(false);

    setQuality('');
    setNome();
    setMeasurementId();
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" size="md" onClick={handleShow}>
       <MovementIcon /> Definir movimentação
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Abrir corrente de saída</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
        <div className='container_descripiton'>
            <h3>{element.name}</h3>
            <p>{element.description}</p>
        </div>
        <div>
              <CustomInput
                  label="OP"
                  type="text"
                  placeholder="OP"
                  value={name}
                  onChange={(e) => setNome(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
        </div>
        <div className="container_element_inputs__two">
            <div>
                <CustomInput
                    label="Quantidade"
                    type="number"
                    placeholder="Quantidade"
                    value={quality}
                    onChange={(e) => setQuality(e.target.value)}
                />
                {erros.quality && <span className='texterror'>{erros.quality}</span>}
            </div>
            <div className='container-panel-select'>
                <CustomSelect
                    label="Unidades de medidas" 
                    options={measures} 
                    value={measurement_id ? measurement_id.target.value : ''} 
                    onChange={setMeasurementId}
                    />
                {erros.measures && <div className='texterror'>{erros.measures}</div>}
            </div>
        </div>
        <div>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Corrente aberta com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleNewFrequency}>
            Abrir corrente
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OpenCurrent;