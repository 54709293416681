import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Container, Row } from "react-bootstrap";
import InputsService from "../../../../services/inputs";
import "../../index.scss";
import Convert from "../../../../utils/convert";
import FormPIcon from "../../../../components/icons/form_p";
import CloseEventIcon from "../../../../components/icons/close_event";
import DataTable from "react-data-table-component";
import EditIcon from "../../../../components/icons/edit";
import BackIcon from "../../../../components/icons/back";
import FrequencyService from "../../../../services/frequency";
import PropertiesAlert from "../../components/properties_alert";
import PropertiesInfo from "../../components/propeties_info";

const Element = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState("");
  const [dataElement, setDataElement] = useState("");
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const elementData = JSON.parse(localStorage.getItem("elementData"));
  const elementsFlowData = JSON.parse(localStorage.getItem("elementsFlowData"));
  const [frequencys, setDataFrequencys] = useState([]);

  const id = useParams();

  const findElementName = (target, elements) => {
    const found = elements.find(
      (element) =>
        element.element_id === target.element_id &&
        element.flowchart_id === parseInt(target.flowchart_id)
    );
    return found ? found.name : "Elemento não encontrado";
  };

  const findElementProperties = (target, elements) => {
    const found = elements.find(
      (element) =>
        element.element_id === target.element_id &&
        element.flowchart_id === parseInt(target.flowchart_id)
    );
    return found.properties;
  };

  const listProperties = findElementProperties(elementData, elementsFlowData);

  useEffect(() => {
    const fetchFrequency = async () => {
      const frequency = await FrequencyService.getFrequencys();
      if (frequency) {
        let frequencySelect = frequency.data.data;
        let transformedFrequency = frequencySelect
          .filter((frequency) => frequency.active)
          .map((frequency) => ({
            label: frequency.minutes,
            value: frequency.frequency_id,
          }));
        setDataFrequencys(transformedFrequency);
      } else {
        console.log("Não foi possível obter os usuários.");
      }
    };

    fetchFrequency();
  }, [id]);

  const getFrequencyMinutes = (value) => {
    const frequency_name = frequencys.find((item) => item.value === value);
    return frequency_name ? frequency_name.label : "Desconhecido";
  };

  function checkFrequency() {
    const responses = JSON.parse(localStorage.getItem("propertiesData"));

    // Mapeia as propriedades por `property_id` para facilitar a comparação
    const propertiesMap = listProperties.reduce((acc, property) => {
      acc[property.property_id] = property;
      return acc;
    }, {});

    // Agrupa as respostas por `property_id` e mantém apenas o último registro
    const groupedResponses = responses.reduce((acc, response) => {
      const propertyId = response.element_property.property_id;
      if (
        !acc[propertyId] ||
        new Date(response.create_at) > new Date(acc[propertyId].create_at)
      ) {
        acc[propertyId] = response;
      }
      return acc;
    }, {});

    if (!responses || responses.length === 0) {
      const result = Object.values(listProperties).map((response) => {
        return {
          name: response.name,
          valid: false,
          message: "A propriedade precisa ser coletada",
        };
      });

      return result;
    }

    // Verifica se cada última resposta está dentro da frequência
    const results = Object.values(groupedResponses)
      .map((response) => {
        const property = propertiesMap[response.element_property.property_id];
        if (!property) {
          return {
            responseId: response.data_id,
            valid: false,
            message: "Propriedade não encontrada.",
          };
        }

        const frequencyMinutes = parseInt(
          getFrequencyMinutes(property.frequency_id)
        ); // Frequência em minutos
        const lastRecordTime = new Date(response.create_at); // Hora do último registro
        const currentTime = new Date();

        if (frequencyMinutes > 0) {
          // Calcula a diferença em minutos
          const timeDifferenceMinutes =
            (currentTime - lastRecordTime) / (1000 * 60);

          const isValid = timeDifferenceMinutes < frequencyMinutes;

          return {
            name: response.element_property.name,
            valid: isValid,
            timeDifferenceMinutes,
            message: isValid
              ? "O registro está dentro da frequência."
              : `O último registro foi realizado há ${parseInt(
                  timeDifferenceMinutes
                )} minuto(s), excedendo o limite de frequência estabelecido de ${parseInt(
                  frequencyMinutes
                )} minuto(s).`,
          };
        } // Retorno padrão para casos onde `frequencyMinutes <= 0`
        return {
          valid: true,
        };
      })
      .filter((result) => !result.valid);

    return results;
  }

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      }
    };
    const fetchElementItems = async () => {
      const element_data = JSON.parse(localStorage.getItem("elementData"));
      if (element_data) {
        try {
          const Element = await InputsService.getElement(
            id.id,
            element_data.element_id,
            element_data.event_id
          );
          if (Element.status === 200) {
            setDataElement(Element.data.data);
            localStorage.setItem(
              "propertiesData",
              JSON.stringify(Element.data.data.event_data)
            );
          }
        } catch (error) {}
      }
    };

    fetchRole();
    fetchElementItems();
  }, [navigate, id]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("OPERATOR")) {
        navigate("/login");
      }
    } else if (!currentUser) {
      navigate("/login");
    }
  }, [roleUser, currentUser, navigate]);

  if (!dataElement) {
    return <div>Carregando...</div>;
  }

  const closeEvent = async () => {
    const element_data = JSON.parse(localStorage.getItem("elementData"));
    if (element_data) {
      try {
        const Element = await InputsService.closeEvent(
          id.id,
          element_data.element_id,
          element_data.event_id
        );
        if (Element.status === 200) {
          setErros({});
          setAlert(true);

          setTimeout(() => {
            setAlert(false);
            navigate(-1);
          }, 2000);
        } else {
          erros.submit = Element.message;
          setErros(erros);
          setAlert(true);
        }
      } catch (error) {}
    }
  };

  const fillForm = () => {
    navigate(`/gestaoformularios/processo/elemento/formulario/${id.id}`);
  }

  const EditProperties = ({ data_id }) => (
    <Link
      to={`/gestaoformularios/processo/elemento/editarpropriedade/${data_id}`}
    >
      <EditIcon />
    </Link>
  );
  const columns = [
    {
      name: "Nome",
      selector: (row) => row.element_property.name,
      sortable: true,
    },
    {
      name: "Valor",
      selector: (row) => row.value,
      cell: (row) =>
        row.element_property.data_type === "DATE"
          ? Convert.convertDataBR(row.value) // Exibe data formatada
          : row.value, // Exibe valor normal
      sortable: true,
    },
    {
      name: "Criado em",
      selector: (row) => row.create_at,
      cell: (row) => Convert.convertDataBR(row.create_at),
      sortable: true,
      center: "true",
    },
    {
      name: "Ações",
      selector: (row) => row.data_id,
      cell: (row) => <EditProperties data_id={row.data_id} />,
      width: "90px",
    },
  ];

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="container_datatable_expanded">
        <p>
          <strong>Descrição:</strong> {data.element_property.description}
        </p>
      </div>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Propriedades por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  const Back = ({ onClick }) => (
    <button onClick={onClick} style={{ background: "none", border: "none" }}>
      <BackIcon />
    </button>
  );

  const productionInputEventIds = dataElement.flow_inputs.map(
    (item) => item.production_input.event_id
  );
  return (
    <Container className="container-custom-fluid">
      <Row>
        <header className="header  header_operator">
          <div>
            {/* <Breadcrumbs />  */}
            <Back onClick={handleBackPage} />
            <h1>{findElementName(elementData, elementsFlowData)}</h1>
          </div>
          <div className="container_header_close_event">
            {roleUser.includes("OPERATOR") && (
              <>
                <Button variant="outline-danger" size="md" onClick={closeEvent}>
                  <CloseEventIcon />
                  Fechar lote
                </Button>

                <Button
                  className="container_header_form"
                  variant="primary"
                  size="md"
                  onClick={fillForm}
                >
                  <FormPIcon />
                  Preencher formulário
                </Button>
              </>
            )}
          </div>
        </header>
        <section className="container_card_element element">
            <div className="container_card_inputs">
            <h2>Entradas</h2>
            <PropertiesInfo eventIds={productionInputEventIds} />
          </div>
          <div className="container_cards_elements" style={{marginTop: "1.5rem"}}>
            {dataElement.flow_inputs.length > 0 ? (
              dataElement.flow_inputs
                .sort(
                  (a, b) =>
                    new Date(b.production_input.create_at) -
                    new Date(a.production_input.create_at)
                ) // Ordena de mais recente para mais antigo
                .map((input, index) => (
                  <div className="container_cards_element" key={index}>
                    <div className="card_flowchart">
                      <div className="card_title">
                        <div className="container_card_open">
                          <h2 className="flowchart-name">
                            {input.production_input.name}
                          </h2>
                        </div>
                      </div>
                      <div className="card_body">
                        <span>
                          <strong>Quantidade:</strong>{" "}
                          {input.production_input.quantity}
                          {input.production_input.measurement.symbol}
                        </span>
                        <span>
                          <strong>Data de Criação:</strong>{" "}
                          {Convert.convertDataBR(
                            input.production_input.create_at
                          )}
                        </span>
                        <span>
                          <strong>Usado:</strong>{" "}
                          {input.production_input.is_used ? "Sim" : "Não"}
                        </span>
                        <span>
                          <strong>Aprovado:</strong>{" "}
                          {input.production_input.approved ? "Sim" : "Não"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <p>Sem entradas registradas.</p>
            )}
          </div>

          <h2>Saídas</h2>
          <div className="container_cards_elements">
            {dataElement.flow_outputs.length > 0 ? (
              dataElement.flow_outputs
                .sort((a, b) => new Date(b.create_at) - new Date(a.create_at)) // Ordena de mais recente para mais antigo
                .map((output, index) => (
                  <div className="container_cards_element" key={index}>
                    <div className="card_flowchart">
                      <div className="card_title">
                        <h2 className="flowchart-name">{output.name}</h2>
                      </div>
                      <div className="card_body">
                        <span>
                          <strong>Quantidade:</strong> {output.quantity}
                          {output.measurement.symbol}
                        </span>
                        <span>
                          <strong>Data de Criação:</strong>{" "}
                          {Convert.convertDataBR(output.create_at)}
                        </span>
                        <span>
                          <strong>Usado:</strong>{" "}
                          {output.is_used ? "Sim" : "Não"}
                        </span>
                        <span>
                          <strong>Aprovado:</strong>{" "}
                          {output.approved ? "Sim" : "Não"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <p>Sem saídas registradas.</p>
            )}
          </div>

          <div className="element_properties_container">
            <h2>Propriedades</h2>
            {checkFrequency().length > 0 && (
              <PropertiesAlert checkFrequency={checkFrequency()} />
            )}
          </div>
          <DataTable
            columns={columns}
            data={[...dataElement.event_data].sort(
              (a, b) => new Date(b.create_at) - new Date(a.create_at)
            )} // Ordena de mais recente para mais antigo
            pagination
            responsive
            striped
            paginationComponentOptions={paginationComponentOptions}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            noDataComponent={
              <div>Nenhuma propriedade disponível ou cadastrada</div>
            }
          />

          <div className="close_event_alert">
            {alert && !erros.submit && (
              <Alert
                variant="success"
                onClose={() => setAlert(false)}
                dismissible
                show={alert}
              >
                Eventos fechados com sucesso!
              </Alert>
            )}
            {alert && erros.submit && (
              <Alert
                variant="danger"
                onClose={() => setAlert(false)}
                dismissible
                show={alert}
              >
                {erros.submit}
              </Alert>
            )}
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default Element;
