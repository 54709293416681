import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form, Modal, Alert } from "react-bootstrap";
import Breadcrumbs from "../../components/breadcrumbs";
import DataTable from "react-data-table-component";
import VariableService from "../../services/variable";
import EditIcon from "../../components/icons/edit";
import "./index.scss";
import TokenService from "../../services/token.service";
import api from "../../services/api";
import Loading from "../../components/loading";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const VariablesPage = () => {
  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [variables, setVariables] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState({
    name: "",
    step: "",
    property: "",
    aggregator: "",
    isCalculated: false,
    expression: "",
    variables: [],
    description: "",
  });
  const [error, setError] = useState("");
  const [elements, setElements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [editingVariableId, setEditingVariableId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const columns = [
    { name: "Nome", selector: (row) => row.name ?? "-", sortable: true },
    {
      name: "Propriedade Original",
      selector: (row) => row.property.name ?? "-",
      sortable: true,
    },
    {
      name: "Etapa do Processo",
      selector: (row) => row.property.element_id ?? "-",
      sortable: true,
    },
    {
      name: "Agregador",
      selector: (row) => row.aggregator ?? "-",
      sortable: true,
    },
    {
      name: "Ações",
      cell: (row) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="link"
            onClick={() => {
              setIsEditing(true);
              setEditingVariableId(row.variable_id);
              setForm({
                name: row.name,
                step: row.property?.element_id || "",
                property: row.property?.property_id || "",
                aggregator: row.aggregator || "",
                description: row.description || "",
              });
              setIsModalOpen(true);
            }}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (form.step) {
      fetchProperties(form.step);
    }
  }, [form.step]);

  const handleEditClick = async (row) => {
    setIsEditing(true);
    setEditingVariableId(row.variable_id);
    setIsModalOpen(true);
    setIsLoading(true);

    try {
      const propertiesResponse = await VariableService.fetchElementProperties(
        selectedProcess,
        row.property?.element_id
      );
      setProperties(propertiesResponse);

      setForm({
        name: row.name,
        step: row.property?.element_id || "",
        property: row.property?.property_id || "",
        aggregator: row.aggregator || "",
        description: row.description || "",
        isCalculated: row.isCalculated || false,
        expression: row.expression || "",
        variables: row.variables || [],
      });
    } catch (error) {
      console.error("Erro ao carregar propriedades ao editar:", error.message);
      setError("Erro ao carregar propriedades ao editar.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVariableChange = (index, field, value) => {
    setForm((prev) => ({
      ...prev,
      variables: prev.variables.map((variable, i) =>
        i === index ? { ...variable, [field]: value } : variable
      ),
    }));
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const fetchedProcesses = await VariableService.fetchProcesses();
        setProcesses(fetchedProcesses);
      } catch (err) {
        console.error("Erro ao carregar processos:", err); // Log para detalhes do erro
        setError(err.message);
      }
    };

    fetchInitialData();
  }, []);

  const handleProcessChange = async (e) => {
    const processId = e.target.value;
    setSelectedProcess(processId);

    if (!processId) {
      setError("Selecione um processo válido.");
      setVariables([]);
      return;
    }

    try {
      setIsLoading(true);
      const fetchedElements = await VariableService.fetchElementsByProcess(
        processId
      );
      const allVariables = await VariableService.fetchVariables(processId);

      const filteredVariables = allVariables
        .filter((variable) => variable.flowchart_id === Number(processId))
        .sort((a, b) => a.name.localeCompare(b.name));

      setElements(fetchedElements);
      setVariables(filteredVariables);
      setProperties([]);
    } catch (err) {
      console.error("Erro ao buscar elementos ou variáveis:", err.message);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleElementChange = async (e) => {
    const elementId = e.target.value;
    setForm((prev) => ({ ...prev, step: elementId, property: "" }));

    if (!elementId || !selectedProcess) {
      setProperties([]);
      return;
    }

    try {
      const propertiesResponse = await VariableService.fetchElementProperties(
        selectedProcess,
        elementId
      );
      setProperties(propertiesResponse);
    } catch (err) {
      console.error(
        "Erro ao carregar propriedades da etapa selecionada:",
        err.message
      );
      setError("Erro ao carregar propriedades da etapa selecionada.");
    }
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => {
      const newValue = type === "checkbox" ? checked : value;

      if (prev[name] !== newValue) {
        setFormChanged(true);
      }

      return { ...prev, [name]: newValue };
    });

    if (name === "step") {
      fetchProperties(value);
    }
  };

  const fetchProperties = async (stepId) => {
    if (!selectedProcess || !stepId) {
      setProperties([]);
      return;
    }

    try {
      const propertiesResponse = await VariableService.fetchElementProperties(
        selectedProcess,
        stepId
      );
      setProperties(propertiesResponse);
    } catch (err) {
      setError("Erro ao carregar propriedades.");
    }
  };

  const isFormValid = () => {
    return (
      form.name.trim() !== "" &&
      form.step !== "" &&
      form.property !== "" &&
      form.aggregator !== ""
    );
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setEditingVariableId(null);
    setFormChanged(false);
    resetForm();
    setProperties([]);
  };

  const resetForm = () => {
    setForm({
      name: "",
      step: "",
      property: "",
      aggregator: "",
      isCalculated: false,
      expression: "",
    });
    setError("");
  };

  const handleFormSubmit = async () => {
    if (
      !form.name ||
      !form.aggregator ||
      !selectedProcess ||
      !form.step ||
      !form.property
    ) {
      setError("Todos os campos obrigatórios devem ser preenchidos.");
      return;
    }

    const selectedProperty = properties.find(
      (prop) => prop.property_id === Number(form.property)
    );
    if (!selectedProperty) {
      setError("A propriedade selecionada não é válida.");
      return;
    }

    const payload = {
      name: form.name,
      description: form.description || "",
      aggregator: form.aggregator,
      flowchart_id: selectedProcess,
      element_id: form.step,
      property_id: Number(form.property),
    };

    try {
      setIsLoading(true);
      if (isEditing) {
        await VariableService.updateVariable(editingVariableId, payload);
        setSuccessMessage("Variável atualizada com sucesso!");
      } else {
        await VariableService.createVariable(payload);
        setSuccessMessage("Variável criada com sucesso!");
      }

      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 5000);

      const updatedVariables = await VariableService.fetchVariables(
        selectedProcess
      );
      setVariables(updatedVariables);
      handleModalClose();
    } catch (err) {
      console.error(
        "Erro ao salvar variável:",
        err.response?.data || err.message
      );
      setError(err.response?.data?.message || "Erro ao salvar variável.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="container-custom-fluid">
      {isLoading && <Loading />}
      <header className="header">
        <div>
          <Breadcrumbs />
          <h1>Variáveis</h1>
        </div>
      </header>

      <section className="top-row-custom">
        <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
          <Form.Select onChange={handleProcessChange} className="custom-select">
            <option value="">Selecione um processo</option>
            {processes.length > 0 ? (
              processes.map((process) => (
                <option key={process.id} value={process.id}>
                  {process.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Nenhum processo disponível
              </option>
            )}
          </Form.Select>
        </div>
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="primary"
          disabled={!selectedProcess}
        >
          + Criar nova variável
        </Button>
      </section>
      {error && <Alert variant="danger">{error}</Alert>}
      {!error && showSuccessAlert && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessAlert(false)}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      <section style={{ maxWidth: "100%", margin: "0 auto" }}>
        <DataTable
          columns={columns}
          data={variables.sort((a, b) => a.name.localeCompare(b.name))}
          pagination
          striped
          responsive
          noDataComponent={<div>Nenhuma variável encontrada.</div>}
        />
      </section>

      <Modal
        show={isModalOpen}
        onHide={handleModalClose}
        dialogClassName="variable-modal"
      >
        <Modal.Header
          closeButton
          style={{ border: "none" }}
          className="custom-modal-header"
        >
          <Modal.Title className="custom-modal-title">
            {isEditing ? "Editar Variável" : "Criar Nova Variável"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginTop: "0" }}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <div className="custom-row">
              <Form.Group className="custom-row-item">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group className="custom-row-item">
                <Form.Label>Etapa do Processo</Form.Label>
                <Form.Select
                  name="step"
                  value={form.step}
                  onChange={handleElementChange}
                >
                  <option value="">Selecione uma etapa</option>
                  {elements.map((element) => (
                    <option key={element.element_id} value={element.element_id}>
                      {element.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            {/* <div className="custom-row">
              <Form.Group className="custom-row-single-item">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={form.description || ""}
                  onChange={handleFormChange}
                  style={{ width: "100% !important" }}
                />
              </Form.Group>
            </div> */}
            <div className="custom-row">
              <Form.Group className="custom-row-item">
                <Form.Label>Propriedade</Form.Label>
                <Form.Select
                  name="property"
                  value={form.property}
                  onChange={handleFormChange}
                  disabled={!form.step}
                >
                  <option value="">Selecione uma propriedade</option>
                  {properties.map((property) => (
                    <option
                      key={property.property_id}
                      value={property.property_id}
                    >
                      {property.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="custom-row-item">
                <Form.Label>Agregador</Form.Label>
                <Form.Select
                  name="aggregator"
                  value={form.aggregator}
                  onChange={handleFormChange}
                  required
                >
                  <option value="">Selecione um agregador</option>
                  <option value="SUM">Soma</option>
                  <option value="AVG">Média</option>
                  <option value="MIN">Mínimo</option>
                  <option value="MAX">Máximo</option>
                  <option value="MEDIAN">Mediana</option>
                  <option value="FIRST">Primeiro</option>
                  <option value="LAST">Último</option>
                </Form.Select>
              </Form.Group>
            </div>

            {/* <Form.Group
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <div className="toggle-switch">
                <input
                  className="toggle-checkbox"
                  id="is-calculated-toggle"
                  type="checkbox"
                  name="isCalculated"
                  checked={form.isCalculated}
                  onChange={handleFormChange}
                />
                <label
                  className="toggle-label"
                  htmlFor="is-calculated-toggle"
                ></label>
              </div>
              <label>Variável calculada?</label>
            </Form.Group> 
           {form.isCalculated && (
              <>
                <Form.Group>
                  <Form.Label>Expressão</Form.Label>
                  <Form.Control
                    type="text"
                    name="expression"
                    value={form.expression}
                    onChange={handleFormChange}
                  />
                </Form.Group>
                <div style={{ marginTop: "1rem" }}>
                  <Button variant="secondary" onClick={handleAddVariable}>
                    + Adicionar Variável
                  </Button>
                </div>
                {form.variables.map((variable, index) => (
                  <div
                    key={index}
                    className="custom-row"
                    style={{ marginTop: "1rem" }}
                  >
                    <Form.Group className="custom-row-item">
                      <Form.Label>Variável</Form.Label>
                      <Form.Control
                        type="text"
                        value={variable.name}
                        onChange={(e) =>
                          handleVariableChange(index, "name", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="custom-row-item">
                      <Form.Label>Tipo</Form.Label>
                      <Form.Select
                        value={variable.type}
                        onChange={(e) =>
                          handleVariableChange(index, "type", e.target.value)
                        }
                      >
                        <option value="">Selecione o tipo</option>
                        <option value="int">Inteiro</option>
                        <option value="float">Decimal</option>
                        <option value="string">Texto</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                ))}
              </>
            )} */}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            border: "none",
            marginTop: "0",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button variant="secondary" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={isEditing ? !formChanged : !isFormValid()}
          >
            {isEditing ? "Salvar Alterações" : "Criar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default VariablesPage;
