import api from "./api";
import TokenService from "./token.service";

const VariableService = {
  async fetchVariables(processId) {
    if (!processId) {
      throw new Error("ID do processo não fornecido.");
    }

    try {
      const token = TokenService.getUser();
      const response = await api.get(
        `/smc/variable/?flowchart_id=${processId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("Variáveis recebidas:", response.data.data);
      return response.status === 200 ? response.data.data : [];
    } catch (error) {
      console.error(
        "Erro ao buscar variáveis:",
        error.response?.data || error.message
      );
      throw new Error(
        "Não foi possível buscar variáveis devido a um erro no servidor."
      );
    }
  },

  async fetchProcesses() {
    try {
      const token = TokenService.getUser();
      const response = await api.get("/flowchart/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200 && Array.isArray(response.data.data)) {
        return response.data.data.map((flowchart) => ({
          id: flowchart.flowchart_id,
          name: flowchart.name,
        }));
      }
      return [];
    } catch (error) {
      console.error(
        "Erro ao buscar processos. Detalhes:",
        error.response?.data || error.message
      );
      throw new Error("Erro ao buscar processos.");
    }
  },

  async fetchElementsByProcess(processId) {
    if (!processId) {
      throw new Error("ID do processo não fornecido.");
    }

    try {
      const token = TokenService.getUser();
      const response = await api.get(`/flowchart/${processId}/element/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.status === 200 ? response.data.data.elements : [];
    } catch (error) {
      console.error(
        "Erro ao buscar elementos:",
        error.response?.data || error.message
      );
      throw new Error("Não foi possível buscar elementos.");
    }
  },

  async fetchElementProperties(flowchartId, elementId) {
    if (!flowchartId || !elementId) {
      throw new Error("IDs do fluxo ou elemento não fornecidos.");
    }

    try {
      const token = TokenService.getUser();
      const response = await api.get(
        `/flowchart/${flowchartId}/element/${elementId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.data) {
        return response.data.data.properties || [];
      }
      throw new Error(
        "Propriedades não encontradas para o elemento fornecido."
      );
    } catch (error) {
      console.error(
        "Erro ao buscar propriedades do elemento:",
        error.response?.data || error.message
      );
      throw new Error("Não foi possível buscar propriedades do elemento.");
    }
  },

  async createVariable(payload) {
    try {
      const token = TokenService.getUser();
      console.log("Payload enviado para criar variável:", payload);
      const response = await api.post("/smc/variable/", payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log("Resposta da API ao criar variável:", response.data);
      return response.status === 201 ? response.data.data : null;
    } catch (error) {
      console.error(
        "Erro ao criar variável:",
        error.response?.data || error.message
      );
      throw new Error(
        error.response?.data?.message || "Erro ao criar variável."
      );
    }
  },

  async updateVariable(variableId, payload) {
    try {
      const token = TokenService.getUser();
      if (payload.property_id && typeof payload.property_id !== "number") {
        payload.property_id = Number(payload.property_id);
      }

      const response = await api.put(`/smc/variable/${variableId}/`, payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      return response.status === 200 ? response.data : null;
    } catch (error) {
      console.error(
        "Erro ao atualizar variável:",
        error.response?.data || error.message
      );
      throw new Error(
        error.response?.data?.message || "Erro ao atualizar variável."
      );
    }
  },

  // async fetchVariableById(variableId) {
  //   if (!variableId) {
  //     throw new Error("ID da variável não fornecido.");
  //   }
  //   try {
  //     const token = TokenService.getUser();
  //     const response = await api.get(`/smc/variable/${variableId}/`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     return response.status === 200 ? response.data.data : null;
  //   } catch (error) {
  //     console.error("Erro ao buscar variável:", error.response?.data || error.message);
  //     throw new Error("Não foi possível buscar a variável.");
  //   }
  // },
};

export default VariableService;
