import React, { ChangeEvent, useState } from "react";
import { Button, Row, Alert } from "react-bootstrap";
import "./index.scss";
import CustomInput from "../../components/input";

export const EsqueciSenha = () => {
    const [email_r_senha, setEmailRSenha] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string | null>(null); 
    const [alertVariant, setAlertVariant] = useState<"success" | "danger">("success");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailRSenha(e.target.value);
        console.log(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            console.log("Pendente função");
        }
    };

    const handleSubmitResetPassword = () => {
        //validações para as mensagens dos alertas
        if (!email_r_senha) {
            setAlertMessage("Por favor, insira o seu e-mail.");
            setAlertVariant("danger");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email_r_senha)) {
            setAlertMessage("O formato do e-mail é inválido.");
            setAlertVariant("danger");
            return;
        }

        setAlertMessage("E-mail enviado com sucesso! Verifique sua caixa de entrada.");
        setAlertVariant("success");
    };
    
    return (
        <Row className="container_panel_flex_center">
            <div className="container_esquecisenha_panel" onKeyDown={handleKeyDown} tabIndex={0}>
                <img src="/image/logo_login.png" alt="Logo Alpargatas"></img>
                <h4>Esqueci a senha</h4>
                <div className="container_esquecisenha_panel_input">
                    <CustomInput
                        label="E-mail"
                        type="text"
                        placeholder="Digite seu e-mail"
                        value={email_r_senha}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="container_esquecisenha_panel_submit">
                    <Button variant="primary" onClick={handleSubmitResetPassword}>
                        Recuperar senha
                    </Button>
                </div>
                {alertMessage && alertVariant && (
                    <Alert variant={alertVariant} onClose={() => setAlertMessage(null)} dismissible>
                        {alertMessage}
                    </Alert>
                )}
            </div>
            <div className="container_triangle">
                <svg width="375" height="279" viewBox="0 0 375 279" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M376.745 278.207C376.752 278.767 376.298 279.223 375.739 279.219L308.9 278.782C308.014 278.776 307.573 277.705 308.198 277.077L374.225 210.679C374.851 210.051 375.924 210.486 375.934 211.372L376.745 278.207Z" fill="#F48120"/>
                <path d="M308.415 278.432C308.419 278.991 307.963 279.445 307.403 279.439L240.568 278.681C239.681 278.671 239.245 277.598 239.873 276.973L306.219 210.892C306.847 210.267 307.918 210.707 307.924 211.593L308.415 278.432Z" fill="#FCB813"/>
                <path d="M164.539 212.997C164.544 213.557 164.088 214.011 163.528 214.005L96.6924 213.247C95.806 213.237 95.37 212.164 95.9981 211.538L162.343 145.458C162.971 144.832 164.043 145.273 164.049 146.159L164.539 212.997Z" fill="#FCB813"/>
                <path d="M193.809 280.561C193.804 281.106 193.361 281.547 192.815 281.55L97.0204 282.138C96.1256 282.143 95.6746 281.061 96.3086 280.429L193.074 184.05C193.708 183.419 194.789 183.874 194.78 184.769L193.809 280.561Z" fill="#F48120"/>
                <path d="M307.77 209.934C307.774 210.494 307.319 210.948 306.759 210.942L169.686 209.387C168.8 209.377 168.364 208.304 168.992 207.679L305.059 72.1551C305.687 71.5296 306.758 71.9699 306.764 72.8563L307.77 209.934Z" fill="#FCB813"/>
                <path d="M191.509 208.592C191.514 209.152 191.057 209.607 190.497 209.6L169.718 209.343C168.832 209.332 168.397 208.26 169.025 207.635L189.63 187.112C190.258 186.486 191.328 186.926 191.336 187.812L191.509 208.592Z" fill="#EC1C24"/>
                <path d="M390.803 84.4823C390.904 85.1499 390.328 85.7235 389.661 85.6199L306.612 72.7278C305.802 72.6021 305.479 71.6094 306.06 71.0311L376.549 0.823393C377.129 0.245153 378.121 0.572274 378.243 1.38247L390.803 84.4823Z" fill="#F48120"/>
                </svg>
            </div>
        </Row>

    );
}