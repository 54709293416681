import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import "../index.scss";
import FlowchartService from '../../../services/flowchart';
import DynamicStepForm from '../components/form';
import BackIcon from '../../../components/icons/back';


const FillForm = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [formData, setFormData] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [flowchart_id, setFlowcartID] = useState('');
  const id = useParams();

  const location = useLocation(); // Hook para obter a URL e pathname

  useEffect(() => {
    const regex = /^\/gestaoformularios\/processo\/elemento\/editarpropriedade\/(\d+)$/;
    const match = location.pathname.match(regex);

    if (match) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [location]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      } 
    };

    const fetchPropertiesElement = async () => {
      if(!isEdit) {
        const element_data = JSON.parse(localStorage.getItem('elementData'));
        if (element_data) {
          setFlowcartID(element_data.flowchart_id);
          try {
            const Element = await FlowchartService.getElementID(flowchart_id, element_data.element_id);
            if (Element.status === 200) {
              setFormData(Element.data.data.properties)
            }
          } catch (error) {
          }
        }
      } else {
        const event_data = JSON.parse(localStorage.getItem('propertiesData'));
        if (event_data) {
          const result = event_data.find((item) => item.data_id === parseInt(id.id));
          const steps = result.element_property 
          ? [ 
              { 
                ...result.element_property, 
                value: result.value, 
                data_id: result.data_id 
              } 
            ] 
          : [];
          setFormData(steps);
        }
      }
    };

    fetchRole();
    fetchPropertiesElement();
  }, [navigate, flowchart_id, isEdit, id]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("OPERATOR")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  const handleBackPage = () => {
    navigate(-1);
  };

  const Back = ({ onClick }) => (
    <button onClick={onClick} style={{ background: 'none', border: 'none' }}>
      <BackIcon />
    </button>
  );

  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header header_operator'>
      <div>
              {/* <Breadcrumbs />  */}
              <Back onClick={handleBackPage} />
              <div className='header_operador_form'>
                <h1>Formulário</h1>
              </div>
        </div>
      </header>
        <section className='container_card_element'>
          <DynamicStepForm steps={formData} flowchart_id={flowchart_id} />
        </section>
      </Row>
    </Container>
  );
};

export default FillForm;