import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import InputsService from '../../../../services/inputs';
import { useParams } from 'react-router-dom';
import StartIcon from '../../../../components/icons/start';
import Select from 'react-select';
import FlowchartService from '../../../../services/flowchart';

const OpenElement = ({ element, event, handleElementCreated }) => {
  const [show, setShow] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [selectedOutputs, setSelectedOutputs] = useState({});
  const id = useParams();
  const [nameMap, setNameMap] = useState({});
  const [outputs, setOutputs] = useState([]); // Adicionado estado para armazenar os outputs

  // Função para buscar nomes com base no origin_id
  const getNameOriginId = useCallback(async (origin_id) => {
    let dataFlowchart = [];
    try {
      const currentConnections = await FlowchartService.getFlowchartElement(id.id);
      if (currentConnections.status === 200) {
        dataFlowchart = currentConnections.data.data.elements;
      }
    } catch (error) {
      console.error("Error fetching current connections:", error);
    }

    const foundElement = dataFlowchart.find(element => element.element_id === origin_id);
    return foundElement ? foundElement.name : null;
  }, [id]);

  const fetchCurrentConnections = useCallback(async (element_id) => {
    try {
      const currentConnections = await InputsService.currentConnections(id.id, element_id);
      if (currentConnections.status === 200) {
        setOutputs(currentConnections.data.data);
        return currentConnections.data.data;
      }
    } catch (error) {
      console.error("Error fetching current connections:", error);
    }
    return [];
  }, [id.id]); // Dependências do useCallback

  // useEffect para buscar ao montar ou quando element.element_id mudar
  useEffect(() => {
    if (element.element_id) {
      fetchCurrentConnections(element.element_id);
    }
  }, [fetchCurrentConnections, element.element_id]);

  // useEffect para buscar nomes dos outputs
  useEffect(() => {
    const fetchNames = async () => {
      const names = {};
      for (const output of outputs) {
        const name = await getNameOriginId(output.origin_id); 
        names[output.origin_id] = name; 
      }
      setNameMap(names); // Atualiza o estado com os nomes
    };

    if (outputs.length > 0) {
      fetchNames();
    }
  }, [outputs, getNameOriginId]);

  const handleSelectChange = (connectionId, selected) => {
    setSelectedOutputs(prevState => ({
      ...prevState,
      [connectionId]: selected || [], // Mantém as seleções anteriores
    }));
  };

  const handleOpenProcess = async () => {
    let valid = true;
    let erros = {};

    const allSelectedOutputs = [];

    // Itera sobre todas as conexões selecionadas
    for (const connectionId of Object.keys(selectedOutputs)) {
      const connectionOutputs = selectedOutputs[connectionId];

      // Verifica se há valores selecionados
      if (Array.isArray(connectionOutputs)) {
        allSelectedOutputs.push(...connectionOutputs.map(output => output.value)); // Adiciona todos os valores ao array final
      }
    }

    if (valid) {
      try {
        const flowchartsElements = await InputsService.openElementProcess(id.id, element.element_id, allSelectedOutputs);
        if (flowchartsElements.status === 200) {
          setErros({});
          setAlert(true);

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = flowchartsElements.message;
          setErros(erros);
          setAlert(true);
        }
      } catch (error) {
        return error;
      }
    } else {
      setErros(erros);
    }

    handleElementCreated();
  };

  const handleClose = () => {
    setErros({});
    setShow(false);
  };

  const handleShow = () => {
    setShow(true)
    if (element.element_id) {
      fetchCurrentConnections(element.element_id);
    }
  };

  return (
    <>
      <Button variant="primary" size="md" onClick={handleShow}>
        <StartIcon /> Abrir lote
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Abrir processo</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
          <div className='container_descripiton'>
            <h3>{element.name}</h3>
            <p>{element.description}</p>
          </div>
          {element.element_type !== "INPUT" && (
            <div>
              {outputs.map((item) => (
                <div key={item.connection_id}>
                  {/* Mapeia os outputs e renderiza um nome e Select para cada output */}
                    <div key={item.outputs.output_id} style={{ marginBottom: '20px' }}>
                      {/* Exibe o nome antes de cada Select */}
                      <h3>{nameMap[item.origin_id] || 'Carregando...'}</h3>
                      
                      <Select
                        isMulti
                        options={item.outputs.map(o => ({
                          value: o.output_id,
                          label: `${o.name}: ${o.quantity}(${o.measurement.symbol})`
                        }))}
                        value={selectedOutputs[item.connection_id] || []}
                        onChange={(selected) => handleSelectChange(item.connection_id, selected)}
                        placeholder={`Selecionar evento do ${nameMap[item.origin_id] || 'Carregando...'}`}
                      />
                    </div>
                </div>
              ))}
            </div>
          )}
          <div>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Elemento aberto com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleOpenProcess}>
            Iniciar processo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OpenElement;
